<template>
  <a-modal
    :title="title"
    :visible="open"
    :confirmLoading="uploading"
    @cancel="importExcelHandleCancel"
    @ok="importExcelHandleChange"
  >
    <a-spin tip="上传中..." :spinning="uploading">
      <a-upload-dragger
        name="file"
        accept=".xlsx, .xls, .zip"
        :file-list="fileList"
        :multiple="false"
        :remove="handleRemove"
        :before-upload="beforeUpload"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          请将文件拖拽到此处上传❥(^_-)
        </p>
        <p class="ant-upload-hint">
          请至少上传一个Excel文件或者照片的zip压缩文件(照片名称必须为人员工号，如：YAKS-0001.png、YAKS-0002.jpg)
        </p>
      </a-upload-dragger>
      <a-checkbox @change="handleCheckedUpdateSupport" :checked="updateSupport != 0">
        是否更新已经存在的人员台账数据
      </a-checkbox>
      <a @click="importTemplate">下载模板</a>
    </a-spin>
  </a-modal>
</template>

<script>

import { importTemplate, importData } from '@/api/iot/employeeAccount'

export default {
  name: 'ImportExcel',
  props: {
  },
  components: {
  },
  data () {
    return {
      title: '人员台账导入',
      open: false,
      uploadStatus: '',
      fileList: [],
      // 是否禁用上传
      uploading: false,
      updateSupport: 0
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 导入excel窗体关闭 */
    importExcelHandleCancel (e) {
      this.open = false
      this.fileList = []
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 下载模板操作 */
    importTemplate () {
      importTemplate().then(response => {
        this.download(response.msg)
      })
    },
    /** 导入excel窗体开启 */
    importExcelHandleOpen (e) {
      this.open = true
    },
    beforeUpload (file) {
      this.fileList.push(file)
      return false
    },
    /** 导入excel */
    importExcelHandleChange () {
      this.uploading = true
      const { fileList } = this
      const formData = new FormData()
      if (fileList.length === 2) {
        var suffix0 = fileList[0].name.substring(fileList[0].name.lastIndexOf('.') + 1)
        var suffix1 = fileList[1].name.substring(fileList[0].name.lastIndexOf('.') + 1)
        if ((suffix0 === 'xlsx' || suffix0 === 'xls') && (suffix1 === 'xlsx' || suffix1 === 'xls')) {
          this.$warning({
            title: 'Warning',
            content: '不能同时上传两个excel文件'
          })
          this.uploading = false
          return
        }
        if (suffix0 === 'zip' && suffix1 === 'zip') {
          this.$warning({
            title: 'Warning',
            content: '不能同时上传两个zip文件'
          })
          this.uploading = false
          return
        }
        formData.append('file1', fileList[0])
        formData.append('file2', fileList[1])
      } else if (fileList.length === 0) {
        this.$warning({
            title: 'Warning',
            content: '请至少上传一个excel文件或者照片的zip压缩文件'
          })
          this.uploading = false
          return
      } else if (fileList.length > 2) {
        this.$warning({
            title: 'Warning',
            content: '最多只能上传两个文件且必须是excel文件和zip文件'
          })
          this.uploading = false
          return
      } else if (fileList.length === 1) {
        formData.append('file1', fileList[0])
      }

      // formData.append('file', fileList[0])
      // formData.append('file', fileList)
      formData.append('updateSupport', this.updateSupport)
      importData(formData).then(response => {
        this.fileList = []
        // this.$message.success(response.msg)
        this.$message.info(response.msg, 10)
        // this.$info({
        //     title: '导入信息',
        //     content: response.msg
        // })
        this.open = false
        this.$emit('ok')
        this.uploading = false
      }).catch(() => {
        this.uploading = false
      })
    },
    handleCheckedUpdateSupport () {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    },
    handleRemove () {
      this.fileList = []
      this.uploading = false
    }
  }
}
</script>
