<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <a-col :span="5">
          <!-- 机构树 -->
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <!-- 条件搜索 -->
        <a-col :span="19">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="工号" prop="jobNumber">
                    <a-input v-model="queryParam.jobNumber" placeholder="请输入工号" allow-clear />
                  </a-form-item>
                </a-col>
                <template v-if="advanced">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="姓名" prop="name">
                      <a-input v-model="queryParam.name" placeholder="请输入姓名" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="性别" prop="sex">
                      <a-select placeholder="请选择性别" v-model="queryParam.sex" style="width: 100%" allow-clear>
                        <a-select-option v-for="(d, index) in sexOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="生日">
                      <a-range-picker
                        style="width: 100%"
                        v-model="daterangeBirthday"
                        valueFormat="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="年龄">
                      <a-input-number v-model="minAge" :min="1" :max="100" />-<a-input-number
                        v-model="maxAge"
                        :min="1"
                        :max="100"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="身份证" prop="identityCard">
                      <a-input v-model="queryParam.identityCard" placeholder="请输入身份证" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="民族" prop="nation">
                      <a-input v-model="queryParam.nation" placeholder="请输入民族" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="婚姻" prop="marriage">
                      <a-select placeholder="请选择婚姻" v-model="queryParam.marriage" style="width: 100%" allow-clear>
                        <a-select-option v-for="(d, index) in marriageOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="政治面貌" prop="politics">
                      <a-select
                        placeholder="请选择政治面貌"
                        v-model="queryParam.politics"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in politicsOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="首次参加工作时间">
                      <a-range-picker
                        style="width: 100%"
                        v-model="daterangeFirstWorkingTime"
                        valueFormat="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="工作年限">
                      <a-input-number v-model="minWorkYear" :min="0" :max="100" />-<a-input-number
                        v-model="maxWorkYear"
                        :min="0"
                        :max="100"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="在职状态" prop="workState">
                      <a-select
                        placeholder="请选择在职状态"
                        v-model="queryParam.workState"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in workStateOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="离职退休日期" prop="retirementTime">
                      <a-date-picker
                        style="width: 100%"
                        v-model="queryParam.retirementTime"
                        format="YYYY-MM-DD HH:mm:ss"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="用工类型" prop="workType">
                      <a-select
                        placeholder="请选择用工类型"
                        v-model="queryParam.workType"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in workTypeOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="是否怀孕" prop="pregnantStatus">
                      <a-select
                        placeholder="请选择是否怀孕"
                        v-model="queryParam.pregnantStatus"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option
                          v-for="(d, index) in pregnantStatusOptions"
                          :key="index"
                          :value="d.dictValue">{{ d.dictLabel }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="居住情况-原籍" prop="nativePlace">
                      <a-input v-model="queryParam.nativePlace" placeholder="请输入居住情况-原籍" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="居住情况-现籍" prop="currentPlace">
                      <a-input v-model="queryParam.currentPlace" placeholder="请输入居住情况-现籍" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="居住情况-居住地" prop="habitation">
                      <a-input v-model="queryParam.habitation" placeholder="请输入居住情况-居住地" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="联系方式-手机号" prop="phone">
                      <a-input v-model="queryParam.phone" placeholder="请输入联系方式-手机号" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="联系方式-办公室电话" prop="officePhone">
                      <a-input v-model="queryParam.officePhone" placeholder="请输入联系方式-办公室电话" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="联系方式-邮箱" prop="email">
                      <a-input v-model="queryParam.email" placeholder="请输入联系方式-邮箱" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="联系方式-内部邮件" prop="internalMail">
                      <a-input v-model="queryParam.internalMail" placeholder="请输入联系方式-内部邮件" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="教育背景-毕业学校" prop="graduateSchool">
                      <a-input v-model="queryParam.graduateSchool" placeholder="请输入教育背景-毕业学校" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="教育背景-学历" prop="education">
                      <a-select
                        placeholder="请选择教育背景-学历"
                        v-model="queryParam.education"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in educationOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="用工情况-入职时间(司龄)" prop="hiredate">
                      <a-date-picker
                        style="width: 100%"
                        v-model="queryParam.hiredate"
                        format="YYYY-MM-DD HH:mm:ss"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="用工情况-职位(职能)" prop="position">
                      <a-select
                        placeholder="请选择用工情况-职位(职能)"
                        v-model="queryParam.position"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in positionOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="用工情况-职务(职级)" prop="rank">
                      <a-select
                        placeholder="请选择用工情况-职务(职级)"
                        v-model="queryParam.rank"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in rankOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="用工情况-社保号" prop="socialSecurityNumber">
                      <a-input
                        v-model="queryParam.socialSecurityNumber"
                        placeholder="请输入用工情况-社保号"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="(!advanced && 8) || 24" :sm="24">
                  <span
                    class="table-page-search-submitButtons"
                    :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                  >
                    <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button
                    >
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? '收起' : '展开' }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 操作 -->
          <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
            <div class="table-operations">
              <a-button
                type="primary"
                @click="$refs.createForm.handleAdd()"
                v-hasPermi="['tenant:iot:employeeAccount:add']"
              >
                <a-icon type="plus" />新增
              </a-button>
              <a-button
                type="primary"
                :disabled="single"
                @click="$refs.createForm.handleUpdate(undefined, ids)"
                v-hasPermi="['tenant:iot:employeeAccount:edit']"
              >
                <a-icon type="edit" />修改
              </a-button>
              <!-- <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:employeeAccount:remove']">
              <a-icon type="delete" />删除
            </a-button> -->
              <a-button
                type="primary"
                @click="$refs.importExcel.importExcelHandleOpen()"
                v-hasPermi="['tenant:iot:employeeAccount:import']"
              >
                <a-icon type="import" />导入
              </a-button>
              <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:employeeAccount:export']">
                <a-icon type="download" />导出
              </a-button>
              <!-- <a-button
                type="dashed"
                shape="circle"
                :loading="loading"
                :style="{ float: 'right' }"
                icon="reload"
                @click="getList"
              /> -->
            </div>
          </a-toolbar>
          <!-- 增加修改 -->
          <create-form
            ref="createForm"
            :sexOptions="sexOptions"
            :marriageOptions="marriageOptions"
            :politicsOptions="politicsOptions"
            :workStateOptions="workStateOptions"
            :workTypeOptions="workTypeOptions"
            :pregnantStatusOptions="pregnantStatusOptions"
            :educationOptions="educationOptions"
            :positionOptions="positionOptions"
            :rankOptions="rankOptions"
            :statusOptions="statusOptions"
            :deptOptions="deptOptions"
            @ok="getList"
          />
          <WorkExperience ref="workExperience" />
          <LaborContract ref="laborContract" />
          <!-- 上传文件 -->
          <import-excel ref="importExcel" @ok="getList" />
          <!-- 数据展示 -->
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            tid="1"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <span slot="sex" slot-scope="text, record">
              {{ sexFormat(record) }}
            </span>
            <span slot="birthday" slot-scope="text, record">
              {{ parseTime(record.birthday) }}
            </span>
            <span slot="marriage" slot-scope="text, record">
              {{ marriageFormat(record) }}
            </span>
            <span slot="politics" slot-scope="text, record">
              {{ politicsFormat(record) }}
            </span>
            <span slot="workState" slot-scope="text, record">
              {{ workStateFormat(record) }}
            </span>
            <span slot="retirementTime" slot-scope="text, record">
              {{ parseTime(record.retirementTime) }}
            </span>
            <span slot="workType" slot-scope="text, record">
              {{ workTypeFormat(record) }}
            </span>
            <span slot="pregnantStatus" slot-scope="text, record">
              {{ pregnantStatusFormat(record) }}
            </span>
            <span slot="education" slot-scope="text, record">
              {{ educationFormat(record) }}
            </span>
            <span slot="hiredate" slot-scope="text, record">
              {{ parseTime(record.hiredate) }}
            </span>
            <span slot="position" slot-scope="text, record">
              {{ positionFormat(record) }}
            </span>
            <span slot="rank" slot-scope="text, record">
              {{ rankFormat(record) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a-divider type="vertical" v-hasPermi="['tenant:iot:employeeAccount:edit']" />
              <a
                @click="$refs.createForm.handleUpdate(record, undefined)"
                v-hasPermi="['tenant:iot:employeeAccount:edit']"
              >
                <a-icon type="edit" />修改
              </a>
              <!-- <a-divider type="vertical" v-hasPermi="['tenant:iot:employeeAccount:remove']" /> -->
              <!-- <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:employeeAccount:remove']">
                <a-icon type="delete" />删除
              </a> -->
              <a-divider type="vertical" v-hasPermi="['tenant:iot:workExperience:list']" />
              <a
                @click="$refs.workExperience.handleUpdate(record, undefined)"
                v-hasPermi="['tenant:iot:workExperience:list']"
              >
                <a-icon type="edit" />工作经历
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:iot:laborContract:list']" />
              <a
                @click="$refs.laborContract.handleUpdate(record, undefined)"
                v-hasPermi="['tenant:iot:laborContract:list']"
              >
                <a-icon type="edit" />劳动合同
              </a>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listEmployeeAccount, delEmployeeAccount, exportEmployeeAccount } from '@/api/iot/employeeAccount'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
import { treeselect } from '@/api/system/dept'
import DeptTree from './modules/DeptTree'
import WorkExperience from './modules/WorkExperience'
import LaborContract from './modules/LaborContract'
export default {
  name: 'EmployeeAccount',
  components: {
    CreateForm,
    DeptTree,
    ImportExcel,
    WorkExperience,
    LaborContract
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 机构树选项
      deptOptions: [
        {
          id: 0,
          label: '',
          children: []
        }
      ],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 性别字典
      sexOptions: [],
      // 生日时间范围
      daterangeBirthday: [],
      // 年龄范围
      minAge: null,
      maxAge: null,
      // 工作年限范围
      minWorkYear: null,
      maxWorkYear: null,
      // 婚姻字典
      marriageOptions: [],
      // 政治面貌字典
      politicsOptions: [],
      // 首次参加工作时间时间范围
      daterangeFirstWorkingTime: [],
      // 在职状态字典
      workStateOptions: [],
      // 用工类型字典
      workTypeOptions: [],
      // 是否怀孕字典
      pregnantStatusOptions: [],
      // 教育背景-学历字典
      educationOptions: [],
      // 用工情况-职位(职能)字典
      positionOptions: [],
      // 用工情况-职务(职级)字典
      rankOptions: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        deptId: null,
        jobNumber: null,
        name: null,
        sex: null,
        birthday: null,
        identityCard: null,
        nation: null,
        marriage: null,
        politics: null,
        firstWorkingTime: null,
        workState: '1',
        retirementTime: null,
        workType: null,
        pregnantStatus: null,
        nativePlace: null,
        currentPlace: null,
        habitation: null,
        phone: null,
        officePhone: null,
        email: null,
        internalMail: null,
        graduateSchool: null,
        education: null,
        hiredate: null,
        position: null,
        rank: null,
        socialSecurityNumber: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '工号',
          dataIndex: 'jobNumber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属机构',
          dataIndex: 'deptName',
          scopedSlots: { customRender: 'deptName' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备编号',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '排序',
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getTreeselect()
    this.getDicts('sys_user_sex').then((response) => {
      this.sexOptions = response.data
    })
    this.getDicts('iot_account_marriage').then((response) => {
      this.marriageOptions = response.data
    })
    this.getDicts('iot_account_politics').then((response) => {
      this.politicsOptions = response.data
    })
    this.getDicts('iot_account_workState').then((response) => {
      this.workStateOptions = response.data
    })
    this.getDicts('iot_account_workType').then((response) => {
      this.workTypeOptions = response.data
    })
    this.getDicts('iot_account_pregnant').then((response) => {
      this.pregnantStatusOptions = response.data
    })
    this.getDicts('iot_account_edu').then((response) => {
      this.educationOptions = response.data
    })
    this.getDicts('iot_account_position').then((response) => {
      this.positionOptions = response.data
    })
    this.getDicts('iot_account_rank').then((response) => {
      this.rankOptions = response.data
    })
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询人员台账列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (this.daterangeBirthday !== null && this.daterangeBirthday !== '' && this.daterangeBirthday.length !== 0) {
        this.queryParam.params['beginBirthday'] = this.daterangeBirthday[0]
        this.queryParam.params['endBirthday'] = this.daterangeBirthday[1]
      }
      if (this.minAge) {
        this.queryParam.params['beginAge'] = this.minAge
      }
      if (this.maxAge) {
        this.queryParam.params['endAge'] = this.maxAge
      }
      if (this.minWorkYear) {
        this.queryParam.params['beginWorkYear'] = this.minWorkYear
      }
      if (this.maxWorkYear) {
        this.queryParam.params['endWorkYear'] = this.maxWorkYear
      }
      if (
        this.daterangeFirstWorkingTime !== null &&
        this.daterangeFirstWorkingTime !== '' &&
        this.daterangeFirstWorkingTime.length !== 0
      ) {
        this.queryParam.params['beginFirstWorkingTime'] = this.daterangeFirstWorkingTime[0]
        this.queryParam.params['endFirstWorkingTime'] = this.daterangeFirstWorkingTime[1]
      }
      listEmployeeAccount(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const query = { type: 'employee' }
      treeselect(query).then((response) => {
        this.deptOptions = response.data
      })
    },
    // 性别字典翻译
    sexFormat(row, column) {
      return this.selectDictLabel(this.sexOptions, row.sex)
    },
    // 婚姻字典翻译
    marriageFormat(row, column) {
      return this.selectDictLabel(this.marriageOptions, row.marriage)
    },
    // 政治面貌字典翻译
    politicsFormat(row, column) {
      return this.selectDictLabel(this.politicsOptions, row.politics)
    },
    // 在职状态字典翻译
    workStateFormat(row, column) {
      return this.selectDictLabel(this.workStateOptions, row.workState)
    },
    // 用工类型字典翻译
    workTypeFormat(row, column) {
      return this.selectDictLabel(this.workTypeOptions, row.workType)
    },
    // 是否怀孕字典翻译
    pregnantStatusFormat(row, column) {
      return this.selectDictLabel(this.pregnantStatusOptions, row.pregnantStatus)
    },
    // 教育背景-学历字典翻译
    educationFormat(row, column) {
      return this.selectDictLabel(this.educationOptions, row.education)
    },
    // 用工情况-职位(职能)字典翻译
    positionFormat(row, column) {
      return this.selectDictLabel(this.positionOptions, row.position)
    },
    // 用工情况-职务(职级)字典翻译
    rankFormat(row, column) {
      return this.selectDictLabel(this.rankOptions, row.rank)
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeBirthday = []
      this.daterangeFirstWorkingTime = []
      this.minAge = null
      this.maxAge = null
      this.minWorkYear = null
      this.maxWorkYear = null
      this.queryParam = {
        deptId: undefined,
        jobNumber: undefined,
        name: undefined,
        sex: undefined,
        identityCard: undefined,
        nation: undefined,
        marriage: undefined,
        politics: undefined,
        workState: undefined,
        retirementTime: undefined,
        workType: undefined,
        pregnantStatus: undefined,
        nativePlace: undefined,
        currentPlace: undefined,
        habitation: undefined,
        phone: undefined,
        officePhone: undefined,
        email: undefined,
        internalMail: undefined,
        graduateSchool: undefined,
        education: undefined,
        hiredate: undefined,
        position: undefined,
        rank: undefined,
        socialSecurityNumber: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      console.log('changeSize')
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delEmployeeAccount(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    // /** 导入按钮操作 */
    // handleImport () {
    //   var currentId = $.common.isEmpty(fileId) ? 'file' : fileId
    //   $("#" + currentId).trigger("click")
    // },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportEmployeeAccount(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
